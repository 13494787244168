import './canvas.scss';

function Canvas({ ratio, layout, frame, printView, canvas, texture, opacity, onClick, tile, saturation, blur }) {
  const blurAmount = blur && printView === 'canvas' ? blur * 2 : blur;
  function Shapes() {
    return (
      <div
        className="canvas__shapes"
        style={{ filter: `blur(${blurAmount ? blurAmount : 0}rem) saturate(${saturation ? saturation : 1})` }}
      >
        {canvas.map((shape, index) => (
          <div
            style={{ backgroundColor: shape.background_color }}
            className={`canvas__shape_${index} ${shape.show ? '' : 'hide'}`}
            key={`shape--${index}`}
          ></div>
        ))}
      </div>
    );
  }
  return (
    <div
      id="canvas"
      className={`canvas canvas--ratio-${ratio} canvas--${layout} ${
        frame ? `canvas--frame canvas--frame-${frame}` : ''
      } ${printView === 'canvas' ? 'canvas--print-view' : ''} ${opacity ? 'canvas--opacity' : ''}
      ${!texture ? 'canvas--no-texture' : ''}
      ${tile ? 'canvas--tile' : ''}`}
      onClick={onClick}
      data-umami-event={onClick ? 'load-canvas' : ''}
    >
      <div
        className="canvas__inner"
        style={{ backgroundColor: canvas[0].show ? canvas[0].background_color : 'lightyellow' }}
      >
        {tile ? Array.from(Array(16).keys()).map((index) => <Shapes />) : <Shapes />}
        {texture && <div className="canvas__texture"></div>}
      </div>
    </div>
  );
}

export default Canvas;
