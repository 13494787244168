import Canvas from './canvas';
import './collection.scss';

function Collection({ saved, loadSaved, removeFromCollection, RATIOS }) {
  return (
    <div className="collection ">
      <div className="collection__inner">
          {saved.map((s, i) => (
            <div className="collection__item" key={`collection-item--${i}`}>
              <div className="collection__item__buttons">
                {removeFromCollection && <button className="btn btn-primary collection__item__remove" onClick={() => removeFromCollection(i)}  data-umami-event={"remove-from-collection"}>
                  Remove
                </button>                }
              </div>
              <Canvas
                texture={s.texture}
                canvas={s.canvasState}
                frame={false}
                layout={s.layout}
                ratio={RATIOS[s.ratio]}
                onClick={() => loadSaved(saved, i)}
                tile={s.tile}
                saturation={s.saturation}
                blur={s.blur}
              />
            </div>
          ))}
        </div>
    </div>
  );
}
export default Collection;
