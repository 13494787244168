import './controls.scss';

function Controls({
  layout,
  layoutSwap,
  ratioSwap,
  texture,
  setTexture,
  frame,
  setFrame,
  saveItem,
  print,
  opacity,
  setOpacity,
  tile,
  setTile,
  pastel,
  setPastel,
  saturation,
  setSaturation,
  blur,
  setBlur,
  history,
  undo
}) {
  return (
    <div className={`controls controls--canvas--${layout}`}>
      {
        <div className="controls__inner bg-secondary">
          <div className="controls__buttons">
            <button className="btn" onClick={() => layoutSwap()} data-umami-event="layout-swap">
              Layout swap
            </button>
            {/* <button className="btn" onClick={() => colourSwap()}>
            Colour swap
          </button> */}
            <button className="btn" onClick={() => ratioSwap()} data-umami-event="ratio-swap">
              Ratio swap
            </button>
          </div>
          <div className="controls__fieldset">
            <div className="form-control controls__field">
              <label className="label cursor-pointer space-between">
                <span className="label-text">Texture</span>
                <input
                  type="checkbox"
                  className="toggle"
                  checked={texture}
                  onChange={(e) => setTexture(!texture)}
                  data-umami-event="texture-toggle"
                />
              </label>
            </div>
            <div className="form-control controls__field">
              <label className="label cursor-pointer space-between">
                <span className="label-text">Frame</span>
                <input
                  type="checkbox"
                  className="toggle"
                  checked={frame}
                  onChange={(e) => setFrame(!frame)}
                  data-umami-event="frame-toggle"
                />
              </label>
            </div>
            <div className="form-control controls__field">
              <label className="label cursor-pointer space-between">
                <span className="label-text">Opacity</span>
                <input
                  type="checkbox"
                  className="toggle"
                  checked={opacity}
                  onChange={(e) => setOpacity(!opacity)}
                  data-umami-event="opacity-toggle"
                />
              </label>
            </div>
            <div className="form-control controls__field">
              <label className="label cursor-pointer space-between">
                <span className="label-text">Tile</span>
                <input
                  type="checkbox"
                  className="toggle"
                  checked={tile}
                  onChange={(e) => setTile(!tile)}
                  data-umami-event="tile-toggle"
                />
              </label>
            </div>
            <div className="form-control controls__field">
              <label className="label cursor-pointer space-between">
                <span className="label-text">Pastel</span>
                <input
                  type="checkbox"
                  className="toggle"
                  checked={pastel}
                  onChange={(e) => setPastel(!pastel)}
                  data-umami-event="pastel-toggle"
                />
              </label>
            </div>
            <div>
              <div className="form-control controls__field">
                <label className="label cursor-pointer space-between">
                  <span className="label-text pr-2">Saturate</span>
                  <input
                    className="range"
                    type="range"
                    id="saturation-input"
                    name="saturation-input"
                    min="0"
                    max="1"
                    value={saturation}
                    step="0.2"
                    onChange={(e) => setSaturation(e.target.value)}
                    data-umami-event="saturation"
                  />
                </label>
              </div>
              <div className="form-control controls__field">
                <label className="label cursor-pointer space-between">
                  <span className="label-text pr-2">Blur</span>
                  <input
                    className="range"
                    type="range"
                    id="blur-input"
                    name="blur-input"
                    min="0"
                    max="1.5"
                    value={blur}
                    step="0.1"
                    onChange={(e) => setBlur(e.target.value)}
                    data-umami-event="blur"
                  />
                </label>
              </div>
            </div>
          </div>
          {/* {history && history.length > 1 && <button className="btn" onClick={() => undo()}  data-umami-event="undo">
            Undo
          </button>} */}
          <button className="btn" onClick={() => saveItem()}  data-umami-event="save">
            Save
          </button>
          <button className="btn" onClick={() => print()}  data-umami-event="export">
            Export
          </button>
        </div>
      }
    </div>
  );
}

export default Controls;
