import Canvas from './canvas';
import './layouts.scss';

function Layouts({ canvasState, layouts, setLayout }) {
  return (
    <div className="layouts ">
      <div className="layouts__inner">
          {Array.from(Array(layouts).keys()).map((i) => (
            <div className="layouts__item">

              <Canvas
                texture={false}
                canvas={canvasState}
                frame={false}
                layout={i+1}
                ratio={'1x1'}
                onClick={() => setLayout(i+1)}
              />
            </div>
          ))}
        </div>
    </div>
  );
}
export default Layouts;
