import Canvas from './canvas';
import './frames.scss';

function Frames({ canvasState, frames, layout, setFrame }) {
  return (
    <div className="frames ">
      <div className="frames__inner">
          {Array.from(Array(frames).keys()).map((i) => (
            <div className="frames__item">

              <Canvas
                texture={false}
                canvas={canvasState}
                frame={i+1}
                layout={layout}
                ratio={'1x1'}
                onClick={() => setFrame(i+1)}
              />
            </div>
          ))}
        </div>
    </div>
  );
}
export default Frames;
