import './colours.scss';

function Colours({ layout, colourSwap, canvasState, updateShape, opacity, setOpacity }) {
  return (
    <div className={`colours colours--canvas--${layout}`}>
      {
        <div className="colours__inner bg-secondary">
          <div className="colours__buttons">
            <button className="btn" onClick={() => colourSwap()} data-umami-event="palette swap">
              Palette swap
            </button>
          </div>
          <div className="colours__fieldset">
            {canvasState.map((shape, index) => (
              <div className={`colours__field colours__field--${index}`} key={`colour--${index}`}>
                <input
                  type="color"
                  value={shape.background_color}
                  onChange={(e) => updateShape(index, 'background_color', e.target.value)}
                  onFocusOut={()=> {console.log('sss')}}
                  // onFocusCapture={()=>{'shhss'}}
                  onMouseUp={()=>console.log('did it too')}
                  onInput={()=>console.log('did it')}
                  onMouseLeave={()=> console.log('this now')}
                  className="colours__field__color"
                  data-umami-event={`change-colour-${index}`}
                />
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input
                      type="checkbox"
                      className="toggle"
                      checked={shape.show}
                      
                      onChange={(e) => updateShape(index, 'show', !shape.show)}
                      data-umami-event={`colour-toggle-${index}`} data-umami-event-value={`${!shape.show ? 'show' : 'hide'}-${shape.background_color}`}
                    />
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
}

export default Colours;
